<template>
  <b-card>
    <g-form @submit="addExpenses">
      <b-row>
        <b-col md="4">
          <!-- date  -->
          <g-picker
            name="transactionDate"
            :value.sync="selectedItem.transactionDate"
            label-text="date"
            @change="
              (v) => {
                selectedItem.transactionDate = v;
              }
            "
          />
        </b-col>
        <!-- expense  -->
        <b-col md="4">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.expenseId"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="expenseType"
              field="select"
              name="expense"
              :options="expensesTypes"
              rules="required"
              label="arabicName"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <g-field
            rules="required|min_value:1"
            :value.sync="selectedItem.value"
            type="number"
            label-text="value"
            @change="
              (v) => {
                selectedItem.value = v;
              }
            "
          />
        </b-col>
        <!-- netValue -->
        <b-col
          v-if="selectedItem.isTaxApplied"
          md="4"
        >
          <g-field
            label-text="netValue"
            :value.sync="selectedItem.netValue"
            type="number"
            readonly
          />
        </b-col>
        <!-- taxValue -->
        <b-col
          v-if="selectedItem.isTaxApplied"
          md="4"
        >
          <g-field
            label-text="tax"
            :value.sync="selectedItem.taxValue"
            type="number"
            readonly
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.paymentMethod"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="Payment Method"
              field="select"
              name="paymentMethod"
              :options="paymentWays"
              rules="required"
              label="arabicName"
            />
          </b-form-group>
        </b-col>
        <!-- safes  -->
        <b-col
          v-if="selectedItem.paymentMethod == 'safe'"
          md="4"
        >
          <b-form-group>
            <g-field
              :value.sync="selectedItem.safeId"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="Safe Name"
              field="select"
              name="Safe"
              :options="Safes"
              rules="required"
              label="arabicName"
            />
          </b-form-group>
        </b-col>
        <!-- bank  -->
        <b-col
          v-if="selectedItem.paymentMethod === 'other'"
          md="4"
        >
          <b-form-group>
            <g-field
              :value.sync="selectedItem.paymentMethodId"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="Payment Method"
              field="select"
              name="paymentMethodId"
              :options="paymentMethods"
              rules="required"
              label="arabicName"
              @change="
                (v) => {
                  selectedItem.bankId = v.bankId;
                  bankName = v.bankId;
                }
              "
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="selectedItem.paymentMethod === 'other'"
          md="4"
        >
          <g-field
            :value.sync="selectedItem.bankId"
            :dir="isRight ? 'rtl' : 'ltr'"
            :label="isRight ? 'arabicName' : 'englishName'"
            :options="Banks"
            label-text="bankName"
            field="select"
            disabled
          />
        </b-col>
        <b-col md="2">
          <label
            style="font-size: 14px;margin-bottom: 7px;"
            for="isTaxApplied"
          >
            {{ $t('isTaxable' ) }}
          </label>
          <!-- isTaxApplied -->
          <b-form-group>
            <b-form-checkbox
              v-model="selectedItem.isTaxApplied"
              class="mr-0 mt-50"
              name="isTaxApplied"
              inline
              @input="calcTax(selectedItem.value)"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="pt-1">
        <!-- notes -->
        <b-col md="12">
          <b-form-group :label="$t('notes')">
            <b-form-textarea
              id="textarea"
              v-model="selectedItem.notes"
              label-text="Notes"
              rows="3"
              max-rows="6"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="d-flex justify-content-end py-1 pb-2"
        >
          <b-button
            class="mx-1"
            type="submit"
            variant="primary"
            data-action-type="add"
          >
            {{ $t("add") }}
          </b-button>
        </b-col>
        <b-col cols="12">
          <hr>
        </b-col>
      </b-row>
      <b-row class="p-0">
        <b-col>
          <b-table
            :items="items"
            :fields="expensesTableColumns"
            primary-key="id"
            show-empty
            striped
            hover
            foot-clone
            sticky-column
            fixed
            class="position-relative"
            :empty-text="$t('noMatchingRecordsFound')"
          >
            <!-- Column: Name -->
            <template #cell(arabicName)="data">
              <span>
                {{ data.item.arabicName }}
              </span>
            </template>
            <template #head(actions)>
              <span />
            </template>
            <template #cell(actions)="data">
              <div class="text-nowrap">
                <feather-icon
                  :id="`invoice-row-${data.item}-delete-icon`"
                  icon="TrashIcon"
                  stroke="red"
                  class="mx-1 clickable danger"
                  @click="
                    (v) => {
                      removeExpenses(data.item);
                    }
                  "
                />
                <b-tooltip
                  :title="$t('delete')"
                  placement="bottom"
                  :target="`invoice-row-${data.item.id}-delete-icon`"
                />
              </div>
            </template>
            <template #foot()>
              <span />
            </template>
            <template #foot(value)>
              <span> {{ fraction(totalExpenses) }} </span>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </g-form>
  </b-card>
</template>
  <script>
import { paymentWays } from '@/libs/acl/Lookups';

export default {
  emits: ['update:items'],
  props: {
    items: {
      type: [Array, Function],
      default: () => [],
    },
  },
  data() {
    return {
      bankName: '',
      selectedItem: {
        transactionDate: '',
        value: 0,
        paymentWays: null,
        bankId: null,
        safeId: null,
        transactionTime: this.time,
        isTaxApplied: false,
      },
      expensesTypes: [],
      incomesTypes: [],
      Banks: [],
      Safes: [],
      paymentMethods: [],
      transactionDate: this.today,
      paymentWays: paymentWays,
      currentPageList: 1,
      cachedRows: 25,
      cachedPerPage: 1,
      expensesList: [],
    };
  },
  computed: {

    expensesTableColumns() {
      return [
        {
          key: 'expenseArabicName',
          field: 'expenseArabicName',
          label: this.$t('expenseType'),
          sortable: true,
        },
        {
          key: 'value',
          field: 'value',
          label: this.$t('value'),
          sortable: true,
          type: 'number',
        },
        {
          key: 'notes',
          field: 'notes',
          label: this.$t('notes'),
          sortable: true,
        },
        { key: 'actions' },
      ];
    },
    totalExpenses() {
      return this.items.reduce((sum, item) => {
        sum += parseFloat(item.value);
        return sum;
      }, 0);
    },
  },
  watch: {
    'selectedItem.paymentMethod'(newval) {
      if (newval === 'safe') {
        this.selectedItem.bankId = null;
        this.selectedItem.paymentMethodId = null;
        this.bankName = null
      } else if (newval === 'other') {
        this.selectedItem.safeId = null;
      }
    },
    'selectedItem.value'(newVal) {
      if (this.selectedItem.isTaxApplied === true) {
        this.selectedItem.netValue = this.fraction(newVal / (1 + (parseFloat(this.company.taxPercentage) / 100)));
        this.selectedItem.taxValue = this.fraction(newVal - this.selectedItem.netValue);
      } else {
        this.selectedItem.netValue = 0;
        this.selectedItem.taxValue = 0;
      }
    },
  },
  beforeMount() {
    this.getPaymentMethods();
  },
  mounted() {
    this.selectedItem.transactionDate = this.today;
    this.loadObj();
  },
  methods: {
    removeExpenses(item) {
      const filteredItems = this.items.reduce((items, val) => {
        if (val !== item) {
          const index = items.length > 0 ? items[items.length - 1].lineSerial : 0;
          val.lineSerial = index + 1;
          items.push(val);
        }
        return items;
      }, []);
      this.$emit('update:items', filteredItems);
    },
    addExpenses(item) {
      item = this.selectedItem;
      this.items.push({
        expenseArabicName: this.expensesTypes.find(x => x.id === this.selectedItem.expenseId).arabicName,
        expenseEnglishName: this.expensesTypes.find(x => x.id === this.selectedItem.expenseId).englishName,
        sourceTransactionId: this.id,
        lineSerial: this.items.length + 1,
        ...item,
      });
      // this.selectedItem.expenses = this.items
      this.$emit('update:items', this.items);
      this.selectedItem = {
        transactionDate: this.today,
        value: 0,
        paymentWays: null,
        bankId: null,
        safeId: null,
        transactionTime: this.time,
        isTaxApplied: false,
      };
    },
    loadObj() {
      this.getSafes();
      this.getTypes();
      this.getBanks();
      this.getPaymentMethods();
    },
    calcTax(newVal) {
      if (this.selectedItem.isTaxApplied === true) {
        this.selectedItem.netValue = this.fraction(newVal / (1 + (parseFloat(this.company.taxPercentage) / 100)));
        this.selectedItem.taxValue = this.fraction(newVal - this.selectedItem.netValue);
      } else if (this.selectedItem.isTaxApplied === false) {
        this.selectedItem.netValue = 0;
        this.selectedItem.taxValue = 0;
      }
    },
    getTypes() {
      this.get({ url: 'Expenses' }).then((data) => {
        this.expensesTypes = data;
      });
    },
    getSafes() {
      this.get({ url: 'Safes' }).then((data) => {
        this.Safes = data;
      });
    },
    getBanks() {
      this.get({ url: 'Banks' }).then((data) => {
        this.Banks = data;
      });
    },
    getPaymentMethods() {
      this.get({ url: 'PaymentMethods' }).then((data) => {
        this.paymentMethods = data;
      });
    },
    beforeSaveValidationExpense() {
      if (!this.selectedItem.expenseId) {
        // alert here
        this.doneAlert({ text: this.$t('youShouldSelectExpense'), type: 'error' });
        return false;
      }
      return true;
    },
  },
};
  </script>

  <style></style>
